.app_loading {
  background: var(--black-color);
  position: fixed;
  width: 0vw;
  height: 0vh;
  z-index: 0;

  animation: showAnimationLoading step-start calc(var(--selimovic-duration-total-anim));

  /************* Gestion de la première partie de l'animation *************/
  .app_loading_start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -50px;
    height: 100%;

    .app_loading_logo_rotation {
      opacity: var(--opacity);
      animation: hideBlock step-end (var(--selimovic-duration-total-anim) -0.3s),
        loadingLogoRotation var(--selimovic-duration-second-anim) linear var(--selimovic-duration-first-anim),
        loadingLogoGravityFirst var(--selimovic-duration-second-anim) linear var(--selimovic-duration-first-anim);
    }

    .app_loading_bar {
      background: var(--black-color);
      border: 2px solid var(--white-color);
      border-radius: 20px;
      width: 125px;
      height: 24px;
      opacity: var(--opacity);

      animation: hideBlock step-end (var(--selimovic-duration-first-anim) -0.2s);

      .app_loading_bar_progress {
        border-radius: 20px;
        height: 20.5px;
        max-width: calc(100% - 8px);
        margin-left: 1.5%;
        margin-top: 2px;
        background-color: var(--white-color);

        animation: loadingBarProgress linear var(--selimovic-duration-first-anim);
      }
    }
  }

  /************* Gestion de la seconde partie de l'animation *************/
  .app_loading_end {
    .app_loading_logo_rotation {
      opacity: var(--opacity);
      animation: hideBlock step-end var(--selimovic-duration-total-anim),
        loadingLogoRotation var(--selimovic-duration-second-anim) linear var(--selimovic-duration-first-anim),
        loadingLogoGravitySecond var(--selimovic-duration-second-anim) linear var(--selimovic-duration-first-anim);
    }

    .app_loading_logo1,
    .app_loading_logo2,
    .app_loading_logo3 {
      position: absolute;
      top: calc(var(--size-logo) * -1);
    }

    .app_loading_logo1 {
      left: 50px;
    }

    .app_loading_logo2 {
      left: calc(50% - (var(--size-logo) / 2));
      top: calc((var(--size-logo) * -1) - 5rem);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }

    .app_loading_logo3 {
      right: 50px;
    }

    .app_loading_prepare_navbar {
      position: absolute;
      top: calc(var(--size-logo) * -1);
      left: 2rem;

      @media screen and (min-width: 1800px) {
        left: 22rem;
      }

      @media screen and (min-width: 2300px) {
        left: 32rem;
      }

      @media screen and (min-width: 2600px) {
        left: 42rem;
      }

      @media screen and (min-width: 2900px) {
        left: 52rem;
      }

      .app_loading_logo_rotation {
        opacity: var(--opacity);
        animation: hideBlock step-end var(--selimovic-duration-total-anim),
          loadingLogoRotationNavBar calc(var(--selimovic-duration-second-anim) - 0.2s) linear
            var(--selimovic-duration-first-anim) 1,
          loadingLogoGravityNavBar var(--selimovic-duration-second-anim) linear var(--selimovic-duration-first-anim);
      }
    }

    @media screen and (max-width: 600px) {
      .app_loading_logo_rotation {
        opacity: var(--opacity);
        animation: hideBlock step-end var(--selimovic-duration-total-anim),
          loadingLogoRotationPhone var(--selimovic-duration-second-anim) linear var(--selimovic-duration-first-anim),
          loadingLogoGravitySecond var(--selimovic-duration-second-anim) linear var(--selimovic-duration-first-anim);
      }

      .app_loading_logo1,
      .app_loading_logo2,
      .app_loading_logo3 {
        .logo-dimension {
          width: 6rem;
        }
      }

      .app_loading_prepare_navbar {
        position: absolute;
        top: calc(var(--size-logo) * -1);
        left: auto;
        right: 0rem;

        .app_loading_logo_rotation {
          width: 6rem;

          opacity: var(--opacity);
          animation: hideBlock step-end var(--selimovic-duration-total-anim),
            loadingLogoGravityNavBar var(--selimovic-duration-second-anim) linear var(--selimovic-duration-first-anim);
        }
      }
    }
  }
}

/* Permet d'afficher l'animation loading "la page" */
@keyframes showAnimationLoading {
  from {
  }
  to {
    z-index: 100;
    width: 100vw;
    height: 100vh;
  }
}

/* Permet de cacher la progress bar */
@keyframes hideBlock {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Permet de charger la progress bar */
@keyframes loadingBarProgress {
  from {
    width: 5%;
  }
  to {
    width: 100%;
  }
}

/* Permet de faire rotate le logo */
@keyframes loadingLogoRotation {
  from {
  }
  to {
    transform: rotate(500deg);
  }
}

/* Permet de faire rotate le logo en format mobile */
@keyframes loadingLogoRotationPhone {
  from {
  }
  to {
    transform: rotate(2000deg);
  }
}

/* Permet de bouger le logo vers le bas => Première partie animation */
@keyframes loadingLogoGravityFirst {
  from {
  }
  to {
    margin-top: 230vh;
  }
}

/* Permet de bouger le logo vers le bas => Deuxième partie animation */
@keyframes loadingLogoGravitySecond {
  from {
  }
  to {
    margin-top: calc(115vh + var(--size-logo));
  }
}

/* Permet de bouger le logo NavBar */
@keyframes loadingLogoGravityNavBar {
  from {
  }
  to {
    margin-top: var(--size-logo);
  }
}

/* Permet de faire rotate le logo NavBar */
@keyframes loadingLogoRotationNavBar {
  from {
  }
  to {
    transform: rotate(720deg);
  }
}
