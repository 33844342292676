.app__navbar {
  position: fixed;
  z-index: 10;

  .app__navbar-logo {
    position: fixed;
    top: 0px;
    left: 2rem;
    cursor: pointer;

    @media screen and (min-width: 1800px) {
      left: 18rem;
    }

    @media screen and (min-width: 2300px) {
      left: 28rem;
    }

    @media screen and (min-width: 2600px) {
      left: 42rem;
    }

    img {
      position: relative;
      z-index: 11;
    }

    .app__navbar-links-empty {
      position: absolute;
      top: 67px;
      left: calc(var(--size-logo) - 50px);
      height: 3.5rem;
      background: var(--white-color);
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }

    .app__navbar-links {
      position: absolute;
      top: 67px;
      left: calc(var(--size-logo) - 50px);
      height: 3.5rem;
      background: var(--white-color);
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      border: 2px solid black;

      display: flex;
      justify-content: center;
      align-items: center;

      li {
        flex-direction: column;
        list-style-type: none;
        padding: 14px 11px;
        background-color: rgba($color: #000000, $alpha: 0);
        transition: all 0.2s ease-in-out;

        &:first-child {
          padding-left: 40px;
        }

        &:last-child {
          padding-right: 20px;
        }

        a {
          padding-top: 13px;
          padding-bottom: 16px;
          padding-right: 5px;
          color: var(--black-color);
          font-family: folsom-black;
          font-size: 1.6rem;
          font-weight: bold;
          text-decoration: none;
          text-transform: uppercase;
        }

        &:hover {
          background: var(--black-color);
        }
        &:hover a {
          color: var(--white-color);
        }
      }

      li:last-child {
        padding-right: 30px;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 950px) {
      visibility: hidden;
    }
  }

  .app__navbar-menu {
    position: fixed;
    right: 0;

    img {
      width: 6rem;
      color: var(--white-color);
      cursor: pointer;
    }

    div {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -14px;
      z-index: 5;

      padding: 1rem;

      width: 100%;
      height: 100vh;

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;

      background-color: var(--black-color);

      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
        cursor: pointer;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        li {
          margin: 1.5rem;

          a {
            color: var(--white-color);
            text-decoration: none;
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: 500;

            transition: all 0.3s ease-in-out;

            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
    }
    @media screen and (min-width: 950px) {
      display: none;
    }
  }
}
