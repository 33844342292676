.app {
  color: var(--white-color);
  background: var(--black-color);
  font-family: sfp-pro;
  user-select: none;
}

.logo-dimension {
  width: var(--size-logo);
}

.app-block {
  overflow: hidden;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 17vh;
  padding-bottom: 0rem;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //border: 2px solid red;
}

.head-text {
  font-family: folsom-black;
  font-size: 2.88rem;
  padding-bottom: 10px;

  span {
    background: var(--white-color);
    color: var(--black-color);
    padding-bottom: 6px;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }
}

article {
  font-size: 1.3rem;

  p {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 950px) {
  .app-block {
    padding: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .app-block {
    padding: 2rem;
    padding-top: 10vh;
    padding-bottom: 0rem;
  }
}

@media screen and (min-width: 1800px) {
  .app-block {
    padding-left: 20rem;
    padding-right: 20rem;
  }
}

@media screen and (min-width: 2300px) {
  .app-block {
    padding-left: 30rem;
    padding-right: 30rem;
  }
}

@media screen and (min-width: 2600px) {
  .app-block {
    padding-left: 45rem;
    padding-right: 45rem;
  }
}

.jump {
  padding-top: 3rem;
}

.center {
  text-align: center;
}

.italic {
  font-family: sfp-pro-bold-italic;
}

.bold {
  font-weight: bold;
}
