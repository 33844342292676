.app_portfolio {
  .app_portfolio_block {
    position: relative;

    .app_portfolio_img {
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        height: 550px;
      }
    }

    .app_portfolio_texte {
      font-size: 1.3rem;
      position: absolute;
      left: -380px;
      right: 0px;
      top: 302px;
      text-align: center;
    }

    .app_portfolio_btn {
      position: absolute;
      left: 0;
      right: 0;
      top: 360px;
      text-align: center;

      a {
        text-decoration: none;
        color: black;
      }

      button {
        font-family: sfp-pro;
        padding: 8px;
        background: white;
        font-weight: bold;
        letter-spacing: 0.1rem;
        width: 200px;
        border-radius: 20px;
        color: black;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 1150px) {
      .app_portfolio_img {
        img {
          height: 450px;
        }
      }
      .app_portfolio_texte {
        left: -280px;
        top: 245px;
      }
      .app_portfolio_btn {
        top: 300px;
      }
    }

    @media screen and (max-width: 950px) {
      .app_portfolio_img {
        img {
          height: 350px;
        }
      }
      .app_portfolio_texte {
        font-size: 1.1rem;
        left: -198px;
        top: 190px;
      }
      .app_portfolio_btn {
        top: 230px;
      }
    }

    @media screen and (max-width: 750px) {
      .app_portfolio_img {
        img {
          height: 280px;
        }
      }
      .app_portfolio_texte {
        font-size: 0.9rem;
        left: -154px;
        top: 152px;
      }
      .app_portfolio_btn {
        top: 185px;
      }
    }

    @media screen and (max-width: 500px) {
      .app_portfolio_img {
        img {
          height: 260px;
          margin-left: -20px;
        }
      }
      .app_portfolio_texte {
        font-size: 0.7rem;
        left: -120px;
        top: 137px;
      }
      .app_portfolio_btn {
        top: 170px;
      }
    }
  }
}
