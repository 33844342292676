.app__footer {
  padding: 5px;
  background: white;
  text-align: center;
  color: black;
  font-style: italic;

  @media screen and (max-width: 450px) {
    font-size: 0.8rem;
  }
}
