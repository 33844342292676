.app_contact {
  .app_contact_block {
    display: flex;
    justify-content: center;

    .app_contact_img {
      width: 490px;
      margin-left: -3rem;
      margin-top: -4rem;

      img {
        width: 100%;
      }
    }

    .app_contact_form {
      width: 500px;

      .form_input {
        margin-bottom: 1rem;
        input,
        textarea {
          width: 95%;
          background: black;
          border: 1px solid white;
          color: white;
          padding: 10px 10px;
        }
        ::placeholder {
          color: white;
          opacity: 0.7;
        }
      }

      button {
        cursor: pointer;
        background: white;
        padding: 10px 14px;
        font-family: folsom-black;
        font-size: 1.2rem;
        color: black;
      }

      .message_succes {
        font-family: folsom-black;
        font-size: 2rem;
      }

      .app_contact_reseaux {
        margin-top: 2.5rem;
        margin-left: -5px;
        img {
          margin-right: 0.5rem;
          width: 60px;
          transition: all 0.2s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    @media screen and (max-width: 1000px) {
      .app_contact_img {
        width: 400px;
        margin-top: -3rem;

        img {
          width: 100%;
        }
      }
      .app_contact_form {
        width: 400px;
      }
    }

    @media screen and (max-width: 850px) {
      .app_contact_img {
        width: 300px;
        margin-top: -3rem;

        img {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 750px) {
      .app_contact_img {
        width: 200px;
        margin-top: -2rem;

        img {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 650px) {
      width: 100%;

      .app_contact_img {
        display: none;
      }
    }
  }
}

/* Loader effect */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  top: -50px;
  left: -10px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
