.hiddenWidthNavBar {
}

.app_selimovic_presentation {
}

.app_selimovic_quisuisje {
  .app_selimovic_quisuisje_img {
    display: flex;
    align-items: start;
    opacity: 0;

    img {
      width: 400px;
      margin-top: -2rem;
    }
  }

  @media screen and (max-width: 1100px) {
    .app_selimovic_quisuisje_img {
      img {
        width: 300px;
        margin-top: -9rem;
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;

    .app_selimovic_quisuisje_img {
      img {
        width: 250px;
        margin-top: 0rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .app_selimovic_quisuisje_img {
      img {
        //display: none;
      }
    }
  }
}
