.app_services {
  .app_services_one {
    padding-bottom: 5rem;
    flex-direction: column;

    article {
      .app_services_block {
        display: flex;

        .head-text,
        p {
          width: 100%;
        }
      }
    }
  }

  .app_services_two {
    flex-direction: column;
    padding-top: 8rem;
    padding-bottom: 5rem;

    .app_services_schema_services {
      width: 80%;

      .line {
        height: 5px;
        background-color: white;
      }

      .all_point {
        display: flex;
        justify-content: space-between;
        margin-top: -23px;

        .point {
          position: relative;
          width: 40px;
          height: 40px;
          background: white;
          border-radius: 50px;
          font-family: folsom-black;
          font-size: 1.5rem;
          z-index: 5;

          .point_texte_conception {
            position: absolute;
            top: -50px;
            left: -72px;
          }

          .point_texte_realisation {
            position: absolute;
            top: 60px;
            left: -66px;
          }

          .point_texte_automatisation {
            position: absolute;
            top: -50px;
            left: -110px;
          }

          @media screen and (max-width: 600px) {
            font-size: 0.9rem;

            .point_texte_conception {
              position: absolute;
              top: -30px;
              left: -35px;
            }

            .point_texte_realisation {
              position: absolute;
              top: 55px;
              left: -35px;
            }

            .point_texte_automatisation {
              position: absolute;
              top: -30px;
              left: -60px;
            }
          }
        }
      }
    }

    .app_services_two_texte {
      color: black;
      z-index: 5;
      padding-top: 10vh;
      padding-bottom: 5vh;
      text-align: left;

      p {
        span {
          font-family: folsom-black;
          display: block;
          margin-bottom: 0.5rem;
        }
      }

      p:nth-child(2) {
        margin-left: 2rem;
      }

      p:nth-child(3) {
        margin-left: 4rem;
      }

      p:nth-child(4) {
        margin-left: 6rem;
      }

      p:nth-child(5) {
        margin-left: 8rem;
      }

      p:nth-child(6) {
        margin-left: 10rem;
      }
    }

    @media screen and (max-width: 700px) {
      .app_services_two_texte {
        p:nth-child(n + 2) {
          margin-left: 0rem;
        }
      }
    }
  }
}

.scale {
  transform: scale(50);
}
