@font-face {
  font-family: "folsom-black";
  src: url("./assets/folsom/folsom-black-web.ttf");
  font-display: swap;
}
@font-face {
  font-family: "sfp-pro";
  src: url("./assets/sfppro/sfp-pro-regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: "sfp-pro-bold-italic";
  src: url("./assets/sfppro/sfp-pro-bold-italic.ttf");
  font-display: swap;
}

:root {
  --font-base: sans-serif;

  --black-color: black;
  --white-color: white;

  --size-logo: 10rem;

  /** Gestion animation **/
  --selimovic-duration-first-anim: 2s;
  --selimovic-duration-second-anim: 3s;
  --selimovic-duration-total-anim: 5s;
  --opacity: 0;
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
